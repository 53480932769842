import { render, staticRenderFns } from "./Vsvg.vue?vue&type=template&id=887bca16&scoped=true&"
import script from "./Vsvg.vue?vue&type=script&lang=js&"
export * from "./Vsvg.vue?vue&type=script&lang=js&"
import style0 from "./Vsvg.vue?vue&type=style&index=0&id=887bca16&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "887bca16",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\tfs2010\\WebApps\\Kent\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('887bca16')) {
      api.createRecord('887bca16', component.options)
    } else {
      api.reload('887bca16', component.options)
    }
    module.hot.accept("./Vsvg.vue?vue&type=template&id=887bca16&scoped=true&", function () {
      api.rerender('887bca16', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ClientApp/components/Controls/Vsvg.vue"
export default component.exports